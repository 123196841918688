import React, { useEffect } from 'react';
import useWindowDimensions from '../../utils/UseWindowDimensions';

export default function TV() {
  const { height } = useWindowDimensions();

  useEffect(() => {
    document.title = 'SMILE TV';
  }, []);

  return (
    <iframe
      width="100%"
      height={height}
      src="https://31ice.eventos.tokyo/web/portal/360/event/2016"
      title="SMILE TV">
    </iframe>
  );
}