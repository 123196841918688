import React, { useEffect } from 'react';

export default function AmbassadorChallengeVideoEntryComplete() {

    useEffect(() => {
        document.title = 'SMILE 動画審査エントリー完了';
    }, []);

    return (
        <>
            <span className="hide_pc_tab">
                <div className="mod_header_sp">
                    <span className="left ly_flex_vertical center">
                        <span className="mod_txt_h3 tcr_primary">
                            動画審査エントリー完了
                        </span>
                    </span>
                    <div className="burger-btn" style={{ display: 'none' }}>
                        <span className="bar bar_top"></span>
                        <span className="bar bar_mid"></span>
                        <span className="bar bar_bottom"></span>
                    </div>
                </div>
            </span>
            <div id="movie_entry_form" className="content bg_base_gray">

                <div className="bg_base_white padding_inner">
                    <h2 className="mod_page_title hide_sp">動画エントリー完了</h2>
                    <div className="sp_default">
                        <p className="mb_64 mod_txt_message ta_c">
                            Challengeコンプリート!<br></br>結果をお待ちください。
                        </p>
                        <div className="mb_40 ta_c ly_flex center">
                            <span className="mr_8">
                                <a href="/hr" className="btn_primary small ly_center mb_16 closeModal">閉じる</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <footer id="footer_default" className="bg_base_gray">
                <span className="copy ta_c ly_center mod_txt_copy">Copyright &copy; B-R 31 ICE CREAM CO., LTD. All rights reserved.</span>
            </footer>
        </>
    );
}