import React, { useState, useEffect } from 'react';

import SpHeader from '../../components/SpHeader';
import PcHeader from '../../components/PcHeader';
import PcFooter from '../../components/PcFooter';

import Loading from '../../components/Loading';
import ApiHandler from '../../utils/ApiHandler';
import { GoogleFormLinkRow, LinkRow } from '../../components/FormLink';

export default function Recruitment() {
    const [forms, setForms] = useState({});
    const [loading, isLoading] = useState(true);

    //https://docs.google.com/forms/d/1Sc3kH8tVV60JpjWFo5Er9gCVKRJ9Y1l2IveQhMZ6zds
    useEffect(() => {
        document.title = '採用サポート';
        const handleRequest = () => {
            const apiHandler = new ApiHandler('getForms', data => {
                setForms(data);
                isLoading(false);
            });
            apiHandler.execute({ category: 'recruitement' });
        };
        handleRequest();
    }, []);

    const createLinkList = () => {
        return (
            <ul className="reset_list ly_center">
                {/* <GoogleFormLinkRow
                    formId={forms.recruitementPlan31.formId}
                    windowName='form_owned_plan'
                    disabled={forms.recruitementPlan31.disabled}
                    title={forms.recruitementPlan31.portal.title}
                    imageName='bnr_01.svg'
                    description={forms.recruitementPlan31.portal.description}
                ></GoogleFormLinkRow> */}

                <LinkRow
                    href='https://sites.google.com/g-hundred.co.jp/31-tokuwari/'
                    windowName='form_owned_faj'
                    disabled={forms.recruitementPlanFAJ.disabled}
                    title={forms.recruitementPlanFAJ.portal.title}
                    imageName='bnr_02.svg'
                    description={forms.recruitementPlanFAJ.portal.description}
                ></LinkRow>

                <GoogleFormLinkRow
                    formId={forms.homepageUpdate.formId}
                    windowName='form_homepage'
                    disabled={forms.homepageUpdate.disabled}
                    title={forms.homepageUpdate.portal.title}
                    imageName='bnr_03.svg'
                    description={forms.homepageUpdate.portal.description}
                ></GoogleFormLinkRow>

                <GoogleFormLinkRow
                    formId={forms.recctorApplication.formId}
                    windowName='form_recctor'
                    disabled={forms.recctorApplication.disabled}
                    title={forms.recctorApplication.portal.title}
                    imageName='bnr_04.svg'
                    description={forms.recctorApplication.portal.description}
                ></GoogleFormLinkRow>

                <GoogleFormLinkRow
                    formId={forms.jobopUpdate.formId}
                    windowName='form_jobop'
                    disabled={forms.jobopUpdate.disabled}
                    title={forms.jobopUpdate.portal.title}
                    imageName='bnr_05.svg'
                    description={forms.jobopUpdate.portal.description}
                ></GoogleFormLinkRow>

                <GoogleFormLinkRow
                    formId={forms.otherInquiries.formId}
                    windowName='form_other'
                    disabled={forms.otherInquiries.disabled}
                    title={forms.otherInquiries.portal.title}
                    imageName='bnr_06.svg'
                    description={forms.otherInquiries.portal.description}
                ></GoogleFormLinkRow>
            </ul>
        );
    };

    return (
        <>
            <SpHeader></SpHeader>
            <PcHeader></PcHeader>

            <div className="content ly_is_nav">

                <div className="width_default_pc1030">
                    <h2 className="mod_page_title">採用サポート</h2>
                </div>

                <div className="sp_default">
                    <div className="mod_list mb_40">
                        {forms.recruitementPlan31 ? createLinkList() : ''}
                    </div>
                    <section className="tcr_secondary ta_c mb_40 contact">
                        <h3 className="title mb_8">採用サポートセンター</h3>
                        <p className="lead mb_16">掲載のご相談など、お電話でのお問い合わせはこちらまで</p>
                        <p className="tel mod_txt_h1 fw_n josefin_sans tcr_primary mb_8">03-3358-4212</p>
                        <p className="note mod_txt_normal mb_16">※電話番号が変更になりましたのでご注意ください<br></br>受付時間：平日10時～17時（年末年始・お盆などを除く）</p>
                    </section>
                    <p className="ly_center hide_pc_tab mt_40 mb_40">
                        <img src="/asset/img/common/logo/logo_31.svg" alt="" className="ly_center"></img>
                    </p>
                </div>
            </div>
            <Loading show={loading} />
            <PcFooter></PcFooter>
        </>
    );
}
