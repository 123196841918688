import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

import Recruitment from './pages/hr/Recruitment';
import Meeting from './pages/hr/Meeting';
import NotFound from './pages/404';
import Top from './pages/hr/Top';
import Forbbiden from './pages/403';
import TV from './pages/hr/TV';
import Challenge from './pages/hr/Challenge';
import AmbassadorChallenge from './pages/hr/AmbassadorChallenge';
import AmbassadorChallengeVideoEntryForm from './pages/hr/AmbassadorChallengeVideoEntryForm';
import Cases from './pages/hr/Cases';

import { initializeFirebase } from './utils/FirebaseInitializer';
import AmbassadorChallengeVideoEntryComplete from './pages/hr/AmbassadorChallengeVideoEntryComplete';
import { RecctorManuals } from "./pages/hr/RecctorManuals"
import OpenCloseContextProvider from './context/OpenCloseContext';
import DisplayContextProvider from './context/DisplayContext';

initializeFirebase()

function App() {
  return (
    <Router>
      <OpenCloseContextProvider>
        <DisplayContextProvider>
          <Switch>
            <Redirect exact from="/" to="/hr" />
            <PrivateRoute exact path="/hr">
              <Top />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/cases">
              <Cases />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/recruitment">
              <Recruitment />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/recctor-manuals">
              <RecctorManuals />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/meeting">
              <Meeting />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/tv">
              <TV />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/challenge">
              <Challenge />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/challenge/dream-contest">
              <AmbassadorChallenge />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/challenge/dream-contest/entry/video">
              <AmbassadorChallengeVideoEntryForm />
            </PrivateRoute>
            <PrivateRoute exact path="/hr/challenge/dream-contest/entry/video/complete">
              <AmbassadorChallengeVideoEntryComplete />
            </PrivateRoute>
            <Route component={NotFound} />
          </Switch>
        </DisplayContextProvider>
      </OpenCloseContextProvider>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }) {

  const PORTAL_ALLOW_HOSTS = [
    'localhost',
    'smile31ice-portal.web.app',
    //'smile31ice-tv-test.web.app',
  ];

  const checkAllow = () => {
    const referrer = document.referrer || '';
    const hostname = window && window.location && window.location.hostname ? window.location.hostname : '';
    return PORTAL_ALLOW_HOSTS.indexOf(hostname) !== -1 ||
      (hostname.startsWith('smile31ice-portal--preview') && hostname.endsWith('.web.app')) ||
      referrer.startsWith('https://br.31family.info') ||
      referrer.startsWith('https://stg-br.31family.info') ||
      referrer.startsWith('https://smile.31family.info');
  };

  return (
    <Route
      {...rest}
      render={() => {
        return checkAllow() ? children : <Forbbiden></Forbbiden>
      }}
    />
  );
}

export default App;
