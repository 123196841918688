import React, { useState, createContext, useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

export const OpenCloseContext = createContext();

const OpenCloseContextProvider = props => {

    const [top, setTop] = useState({});
    const [challenge, setChallenge] = useState({});
    const [ambassadorChallenge, setAmbassadorChallenge] = useState({});

    const [openCloseConfigLoaded, setOpenCloseConfigLoaded] = useState(false);

    useEffect(() => {
        firebase.database().ref('configs/openClose').on('value', (snapshot) => {
            const data = snapshot.val()

            const configsTop = data?.['トップ']
            setTop({
                meetingOpened: configsTop?.['店長会議'],
                contestOpened: configsTop?.['コンテスト'],
            });

            const configsChallenge = data?.['チャレンジトップ'];
            setChallenge({
                ambassadorChallengeOpened: configsChallenge?.['アンバサダーチャレンジ'],
                hometatsuFormOpened: configsChallenge?.['ほめ達']?.['フォーム'],
                hometatsuZoomOpened: configsChallenge?.['ほめ達']?.['ZOOM'],
            });

            const configsAmbassadorChallenge = data?.['アンバサダーチャレンジトップ'];
            setAmbassadorChallenge({
                entryOpened: configsAmbassadorChallenge?.['エントリー'],
                testOpened: configsAmbassadorChallenge?.['WEB試験'],
                courseOpened: configsAmbassadorChallenge?.['WEB特別講座'],
                videoEntryOpened: configsAmbassadorChallenge?.['接客動画チャレンジ'],
            });
            setOpenCloseConfigLoaded(true);
        });
    }, []);

    return (
        <OpenCloseContext.Provider value={{ top, challenge, ambassadorChallenge, openCloseConfigLoaded }}>
            {props.children}
        </OpenCloseContext.Provider>
    )
}

export default OpenCloseContextProvider;
