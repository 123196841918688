import firebase from 'firebase/app';

const FUNCTIONS_REGION = 'asia-northeast1';

export default class ApiHandler {

    constructor(name, successCallback) {
        if (!name) {
            throw new Error('name は必須です');
        }
        if (typeof successCallback !== 'function') {
            throw new Error('successCallback 関数は必須です');
        }
        this.name = name;
        this.httpsCallable = firebase.app().functions(FUNCTIONS_REGION).httpsCallable(name)
        this.successCallback = successCallback;
    }

    static getRegion = () => {
        return FUNCTIONS_REGION;
    };

    execute(parameters, responseErrorCallback, exceptionCallback, finallyCallback) {
        this.httpsCallable({ ...parameters }).then(res => {
            if (res.data && res.data.error) {
                const errorOfResponse = res.data.error;
                console.error(`レスポンスにエラーデータがあります。${errorOfResponse}`);
                if (typeof responseErrorCallback === 'function') {
                    responseErrorCallback(errorOfResponse);
                }
            } else {
                this.successCallback(res.data);
            }
        }).catch(e => {
            console.error(`データ取得時にエラーが発生しました。${e}`);
            if (typeof exceptionCallback === 'function') {
                exceptionCallback(e);
            }
        }).finally(() => {
            if (typeof finallyCallback === 'function') {
                finallyCallback();
            }
        });
    
    }
}