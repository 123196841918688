import React, {useCallback, useEffect, useState} from "react"

import firebase from "firebase/app"

import SpHeader from "../../components/SpHeader"
import PcHeader from "../../components/PcHeader"
import Loading from "../../components/Loading"
import PcFooter from "../../components/PcFooter"
import { useRecctorManuals } from "../../utils/useRecctorManuals"
import { needToSendAnalytics } from "../../utils"

const RecctorManualDirName = "recctor-manuals"

// https://firebasestorage.googleapis.com/v0/b/n31-portal.appspot.com/o/recctor-manuals%2Fmanual1.pdf?alt=media
const getRecctorManualUrlByName = async (name) => {
  return await firebase.storage().ref(`${RecctorManualDirName}/${name}`).getDownloadURL()
}

export const RecctorManuals = () => {
  const {data, error} = useRecctorManuals()
  const loading = !data && !error

  useEffect(() => {
    document.title = 'リクターマニュアル'
  }, [])

  return (
    <>
      <SpHeader/>
      <PcHeader/>

      <div className="content ly_is_nav">
        <div className="width_default_pc1030">
          <h2 className="mod_page_title">リクターマニュアル</h2>
        </div>


        <div className="sp_default">
          <div className="mod_list mb_40">
            <ul className="reset_list ly_center">
              {
                data && data.map(({title, storageFileName}) => (
                  <RecctorManualFileLink key={storageFileName} title={title} storageFileName={storageFileName}/>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      <Loading show={loading} />
      <PcFooter/>
    </>
  )
}

const RecctorManualFileLink = ({title, storageFileName}) => {
  const [url, setUrl] = useState()
  useEffect(() => {
    getRecctorManualUrlByName(storageFileName).then(url => {
      setUrl(url)
    })
  }, [storageFileName])

  const clickHandler = useCallback(() => {
    if (needToSendAnalytics()) {
      firebase.analytics().logEvent('click_recctor_manual')
    }
  }, [])

  return !url ? <></> : (
    <li>
      <a target={"_blank"} rel={"noreferrer"} href={url} onClick={clickHandler}>
        <span className="ly_flex">
          <p className="no_shrink"><img src="/asset/img/pages/rictor_manual/pdf.png" alt="pdf"/></p>
          <h3 className="ly_flex_vertical center pl_8"><span>{title}</span></h3>
        </span>
      </a>
    </li>
  )
}