import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/database';
import ApiHandler from './ApiHandler';
import { needToUseFirebaseEmulator, needToSendAnalytics } from './index';

const FIREBASE_CONFIG = {
    apiKey: "AIzaSyD-RnoiQWpI_n408KhYjJkKs9NH8wAEpE0",
    authDomain: "n31-portal.firebaseapp.com",
    databaseURL: "https://n31-portal.firebaseio.com",
    projectId: "n31-portal",
    storageBucket: "n31-portal.appspot.com",
    messagingSenderId: "918562260413",
    appId: "1:918562260413:web:5f0fa709b4667a23a37ed4",
    measurementId: "G-GN3KDZ7DSX"
};

const region = ApiHandler.getRegion();

export const firebaseFunctionsBaseURL = () => {
    return needToUseFirebaseEmulator() ? `http://localhost:5001/${FIREBASE_CONFIG.projectId}/${region}/` : `https://${region}-${FIREBASE_CONFIG.projectId}.cloudfunctions.net/`;
};

export const initializeFirebase = () => {
    firebase.initializeApp(FIREBASE_CONFIG);
    if (needToUseFirebaseEmulator()) {
        firebase.functions().useEmulator("localhost", 5001);
        firebase.app().functions('asia-northeast1').useEmulator("localhost", 5001);
        firebase.storage().useEmulator("localhost", 9199);
        firebase.database().useEmulator("localhost", 9001);
    }

    if (needToSendAnalytics()) {
        firebase.analytics();
    }
}