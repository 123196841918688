import React, { useEffect } from 'react'

export default function Forbbiden() {

  useEffect(() => {
    document.title = 'SMILE アクセス不可画面';
  }, []);

  return (
    <>
      <div className="content">
        <div className="sp_default ly_center">
          <p className="ly_center mt_120 mb_40">
            <img src="/asset/img/common/logo/logo_smile_4.svg" alt="" className="ly_center"></img>
          </p>
          <p className="mod_txt_h3 ta_c">アクセスできません。<br></br>WEB営業連絡書からのアクセスをお願いいたします。</p>
          <br></br>
          <p className="mod_txt_h3 ta_c">IE（Internet Explorer）ではアクセスできません。<br></br>推奨環境のGoogleChromeまたはMicrosoft Edgeで再度営業連絡書からアクセスしてください。</p>
        </div>
      </div>
      <footer id="footer_default" className="bg_base_gray">
        <span className="copy ta_c ly_center mod_txt_copy">Copyright &copy; B-R 31 ICE CREAM CO., LTD. All rights reserved.</span>
      </footer>
    </>
  );
}
