import React from 'react';
import { createGoogleFormURL } from '../utils';

export const LinkRow = (props) => {
    return (
        <li>
            <a href={props.href} target={`_${props.windowName}`} className={props.disabled ? 'disable' : ''}>
                <span className="ly_flex tab_vertical">
                    {props.imageName ? <span className="mr_8"><img src={`/asset/img/pages/recruit_top/${props.imageName}`} alt={props.title} /></span> : <></>}
                    <span>
                        <h3>{props.title}</h3>
                        {props.disabled ?
                            <p className="note">※現状申込み期間外です</p> :
                            <p>{props.description}</p>
                        }
                    </span>
                </span>
            </a>
        </li>
    );
};

export const GoogleFormLinkRow = (props) => {
    return <LinkRow href={createGoogleFormURL(props.formId)} {...props}></LinkRow>;
};
