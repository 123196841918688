const isLocal = (window && window.location && window.location.hostname === 'localhost');

export const createGoogleFormURL = (formId) => {
    if (!formId) {
        throw new Error('form id がありません');
    }
    return `https://docs.google.com/forms/d/e/${formId}/viewform?usp=sf_link`;
}

export const getPrefix = () => {
    return window.location.hostname === 'localhost' ? 'develop' : 'production';
}

export function needToSendAnalytics() {
    return !isLocal;
};

export function needToUseFirebaseEmulator() {
    return isLocal;
};
