import React from 'react';

const PcFooter = () => {

  return (
    <div className="ly_is_nav">
      <footer id="footer_default" className="bg_base_gray">
        <span className="copy ta_c ly_center mod_txt_copy">Copyright &copy; B-R 31 ICE CREAM CO., LTD. All rights
          reserved.</span>
      </footer>
    </div>
  );
};

export default PcFooter;