import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import IFrameUnavailableModal, { isIFrameAvailable } from './IFrameUnavailableModal';
import { useRecctorManuals } from "../utils/useRecctorManuals"
import { OpenCloseContext } from '../context/OpenCloseContext';

const PcHeader = () => {

  const { existManual } = useRecctorManuals();
  const { meetingOpened, contestOpened } = useContext(OpenCloseContext).top;

  const [showSmileTVSetting, isShowSmileTVSetting] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleOpenSmileTV = (e) => {
    e.preventDefault();
    if (isIFrameAvailable()) {
      history.push('/hr/tv');
    } else {
      isShowSmileTVSetting(true);
    }
  };

  const createLi = (href, linkName) => {
    return (
      <li className={href === location.pathname ? 'active' : ''}>
        <a href={href} className="mod_txt_h2">{linkName}</a>
      </li>
    );
  }

  return (
    <>
      <nav className="mod_pc_nav hide_sp ly_flex_vertical side">
        <div className="ly_flex_vertical">
          <div className="mt_24">
            <p>
              <a href="/hr">
                <img src="/asset/img/common/logo/logo_smile_4.svg" alt="" className="ly_center"></img>
              </a>
            </p>
          </div>
          <div className="mt_24">
            <nav className="nav_inner">
              <ul className="reset_list">
                {createLi('/hr/recruitment', '採用サポート')}
                {createLi('/hr/cases', '情報共有')}
                <li>
                  <a href="https://recctor.jp/#/login/a5N4FuyuLsHw6UKLvYwv" className="mod_txt_h2" target="_recctorLogin">リクター</a>
                </li>
                {existManual && createLi('/hr/recctor-manuals', 'マニュアル')}
                {meetingOpened ? createLi('/hr/meeting', 'MEETING') : <></>}
                <li>
                  <a href='/hr/tv' className="mod_txt_h2" onClick={handleOpenSmileTV}>SMILE TV</a>
                </li>
                <li>
                  <a href="https://www2.ms-r.com/MSR/Partner/User/index.asp" className="mod_txt_h2" target="_ms">ミステリーショッパー</a>
                </li>
                {contestOpened ? createLi('/hr/challenge', 'SMILE Challenge') : <></>}
                <li>
                  <a href="https://signage.31ice.com" target="_ds" className="mod_txt_h2">Digital Signage</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="logo-box pb_64">
          <h1>
            <img src="/asset/img/common/logo/logo_31.svg" alt="" className="ly_center"></img>
          </h1>
        </div>
      </nav>
      <IFrameUnavailableModal
        classes={`foeShow ${showSmileTVSetting ? '' : 'hide'}`}
        handleNext={() => {
          isShowSmileTVSetting(false);
          history.push('/hr/tv');
        }}
        handleCancel={() => isShowSmileTVSetting(false)}
      ></IFrameUnavailableModal>
    </>
  );
};

export default PcHeader;