import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import IFrameUnavailableModal, { isIFrameAvailable } from '../../components/IFrameUnavailableModal'
import Loading from '../../components/Loading'

import PcFooter from '../../components/PcFooter'
import PcHeader from '../../components/PcHeader'
import SpHeader from '../../components/SpHeader'
import { createGoogleFormURL } from '../../utils'
import ApiHandler from '../../utils/ApiHandler'
import { NotificationInfo } from "../../components/NotificationInfo"
import { useRecctorManuals } from "../../utils/useRecctorManuals"
import { OpenCloseContext } from '../../context/OpenCloseContext';

export default function Top() {

  const { existManual } = useRecctorManuals();
  const { top, openCloseConfigLoaded } = useContext(OpenCloseContext);

  const [inquiryLink, setInquiryLink] = useState('/hr')
  const [loading, isLoading] = useState(true)
  const [showSmileTVSetting, isShowSmileTVSetting] = useState(false)

  const history = useHistory()

  useEffect(() => {
    document.title = 'SMILE TOP'
    const handleRequest = () => {
      const apiHandler = new ApiHandler('getForm', data => {
        setInquiryLink(data.formId)
        isLoading(false)
      })
      apiHandler.execute({ category: 'recruitement', requestName: 'otherInquiries' })
    }
    handleRequest()
  }, [])

  const handleOpenSmileTV = (e) => {
    e.preventDefault()
    if (isIFrameAvailable()) {
      history.push('/hr/tv')
    } else {
      isShowSmileTVSetting(true)
    }
  }

  return (
    <>
      <SpHeader></SpHeader>
      <PcHeader></PcHeader>

      <div className="content ly_is_nav">

        <div className="reset_list">
          <NotificationInfo />
          <ul className="wrap_list_top">
            <li className="two_column recruit_top">
              <a href="/hr/recruitment" className="left">
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn01_left_pc.png" alt="" className="ly_center hide_sp" />
                  <img src="/asset/img/pages/top/img_btn01_left_sp.png" alt="" className="ly_center hide_pc_tab" />
                </div>
                <div className="text">
                  <h2 className="title staff tcr_outline_white">採用<br className="hide_pc_tab" />サポート</h2>
                </div>
              </a>
              <a href="/hr/cases" className="right">
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn01_right_pc.png" alt="" className="ly_center hide_sp" />
                  <img src="/asset/img/pages/top/img_btn01_right_sp.png" alt="" className="ly_center hide_pc_tab" />
                </div>
                <div className="text">
                  <h2 className="title staff tcr_outline_white">情報共有</h2>
                </div>
              </a>
            </li>
            {
              existManual ? (
                <li className="two_column">
                  <a href="https://recctor.jp/#/login/a5N4FuyuLsHw6UKLvYwv" target="_recctorLogin" className="left">
                    <div className="thumb">
                      <img src="/asset/img/pages/top/img_btn07_left_pc.png" alt="" className="ly_center hide_sp" />
                      <img src="/asset/img/pages/top/img_btn07_left_sp.png" alt="" className="ly_center hide_pc_tab" />
                    </div>
                    <div className="text">
                      <h2 className="title staff tcr_outline_white"><span className="hide_sp">リクター</span>ログイン</h2>
                    </div>
                  </a>
                  <a href="/hr/recctor-manuals" className="right">
                    <div className="thumb">
                      <img src="/asset/img/pages/top/img_btn07_right_pc.png" alt="" className="ly_center hide_sp" />
                      <img src="/asset/img/pages/top/img_btn07_right_sp.png" alt="" className="ly_center hide_pc_tab" />
                    </div>
                    <div className="text">
                      <h2 className="title staff tcr_outline_white">マニュアル</h2>
                    </div>
                  </a>
                </li>
              ) : (
                <li>
                  <a href="https://recctor.jp/#/login/a5N4FuyuLsHw6UKLvYwv" target="_recctorLogin">
                    <div className="thumb">
                      <img src="/asset/img/pages/top/img_rictor_pc.png" alt="" className="ly_center hide_sp"></img>
                      <img src="/asset/img/pages/top/img_rictor_sp.png" alt="" className="ly_center hide_pc_tab"></img>
                    </div>
                    <div className="text">
                      <h2 className="title staff tcr_outline_white">スタッフ応募受付窓口<br className="hide_pc_tab"></br><span
                        className="hide_sp">&nbsp;&nbsp;&nbsp;&nbsp;</span>ログイン</h2>
                    </div>
                  </a>
                </li>
              )
            }
            <li>
              <Link to="/hr/meeting" className={top.meetingOpened ? '' : 'disable'}>
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn02_pc.png?20240430" alt="" className="ly_center hide_sp"></img>
                  <img src="/asset/img/pages/top/img_btn02_sp.png?20240430" alt="" className="ly_center hide_pc_tab"></img>
                </div>
                <div className="text">
                  {top.meetingOpened ?
                    <h2 className="title tcr_outline_white">MEETING</h2> :
                    <>
                      <img src="/asset/img/pages/top/coming_soon_bold.png" alt="" className="hide_sp coming_soon"></img>
                      <img src="/asset/img/pages/top/coming_soon.png" alt="" className="hide_pc_tab coming_soon"></img>
                    </>
                  }
                </div>
              </Link>
            </li>

            <li>
              <a href="/hr/tv" className="openModal_ipad" id="link_smiletv"
                onClick={handleOpenSmileTV}>
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn03_pc.png" alt="" className="ly_center hide_sp"></img>
                  <img src="/asset/img/pages/top/img_btn03_sp.png" alt="" className="ly_center hide_pc_tab"></img>
                </div>
                <div className="text">
                  <h2 className="title tcr_outline_white">動画で楽しく<br className="hide_pc_tab"></br>SMILEをもっと！</h2>
                </div>
              </a>
            </li>
            <li>
              <a href="https://www2.ms-r.com/MSR/Partner/User/index.asp" id="link_ms" target="_ms">
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn04_pc.png" alt="" className="ly_center hide_sp"></img>
                  <img src="/asset/img/pages/top/img_btn04_sp.png" alt="" className="ly_center hide_pc_tab"></img>
                </div>
                <div className="text">
                  <h2 className="title mystery tcr_outline_white">ミステリーショッパー<br className="hide_pc_tab"></br><span
                    className="hide_sp">&nbsp;&nbsp;&nbsp;&nbsp;</span>ログイン</h2>
                </div>
              </a>
            </li>
            <li>
              <a href="/hr/challenge" className={top.contestOpened ? '' : 'disable'}>
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn05_pc.png" alt="" className="ly_center hide_sp"></img>
                  <img src="/asset/img/pages/top/img_btn05_sp.png" alt="" className="ly_center hide_pc_tab"></img>
                </div>
                <div className="text">
                  {top.contestOpened ?
                    <h2 className="title mystery tcr_outline_white">あなたのチャレンジで<br className="hide_pc_tab"></br>SMILEをもっと!</h2> :
                    <>
                      <img src="/asset/img/pages/top/coming_soon_bold.png" alt="" className="hide_sp coming_soon"></img>
                      <img src="/asset/img/pages/top/coming_soon.png" alt="" className="hide_pc_tab coming_soon"></img>
                    </>
                  }
                </div>
              </a>
            </li>
            <li>
              <a href="https://signage.31ice.com" target="_ds">
                <div className="thumb">
                  <img src="/asset/img/pages/top/img_btn06_pc.png" alt="" className="ly_center hide_sp"></img>
                  <img src="/asset/img/pages/top/img_btn06_sp.png" alt="" className="ly_center hide_pc_tab"></img>
                </div>
                <div className="text">
                  <h2 className="title mystery tcr_outline_white">タテ型ディスプレイ<br></br>店頭VTR</h2>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <div className="ly_flex sp_vertical center ly_center mx_700">
          <div className="ly_center ta_c mt_40">
            <p className="mod_txt_message mb_16"><span className="josefin_sans">SMILE LETTER</span>追加<br />お申込みはこちら</p>
            <p><a href="https://forms.gle/SMfbg4RbDEumjHy49" target="_letter" className="btn_primary">お申し込み</a></p>
          </div>

          <div className="ly_center ta_c mt_40">
            <p className="mod_txt_message mb_16"><span className="josefin_sans">SMILE</span>についての<br className="hide_sp" />お問い合わせはこちらから</p>
            {inquiryLink && <p><a href={createGoogleFormURL(inquiryLink)} target="_inquiry" className="btn_primary">お問い合わせ</a></p>}
          </div>
        </div>

        <p className="pb_32 hide_sp"></p>

        <p className="ly_center hide_pc_tab mt_16 mb_16">
          <img src="/asset/img/common/logo/logo_31.svg" alt="" className="ly_center"></img>
        </p>

      </div>
      <Loading show={loading || openCloseConfigLoaded === false} />
      <IFrameUnavailableModal
        classes={`foeShow ${showSmileTVSetting ? '' : 'hide'}`}
        handleNext={() => {
          isShowSmileTVSetting(false)
          history.push('/hr/tv')
        }}
        handleCancel={() => isShowSmileTVSetting(false)}
      ></IFrameUnavailableModal>
      <PcFooter></PcFooter>
    </>
  )
}