import React from 'react';
import { Link } from 'react-router-dom';
import UAParser from 'ua-parser-js';

import './FadeOut.css';

export const isIFrameAvailable = () => {
    const parser = new UAParser();
    const UA = parser.getResult();
    console.log(UA.device.model + ',' + UA.os.version);

    if (UA.device.model !== 'iPad') {
        return true;
    }
    const version = UA.os.version;
    const index = version.indexOf('.');
    const majarVersion = Number(index !== -1 ? version.substring(0, index) : version);
    if (majarVersion < 14) {
        return false;
    }
    //14.2 未満では iframe 使えない。
    return version.startsWith('14.1') ? false : true;
}

const IFrameUnavailableModal = (props) => {

    const handleNext = () => {
        props.handleNext();
    };
    const handleCancel = () => {
        props.handleCancel();
    };

    return (
        <section id="modalArea" className={`modalArea notice_ipad ${props.classes}`}>
            <div id="modalBg" className="modalBg" onClick={handleCancel}></div>
            <div className="modalWrapper">
                <div className="modalContents">
                    <div className="title ly_flex wrap center mb_8 mt_32 ta_c">
                        <p className="mod_txt_h2">～iPadで</p>
                        <span className="ly_flex_vertical center">
                            <img src="/asset/img/common/logo/logo_smile_modal_ipad.svg" alt="" className="ly_center"></img>
                        </span>
                        <p className="mod_txt_h2">を閲覧いただく方へ～</p>
                    </div>

                    <div className="content">
                        <p className="ipad_title mb_8">iPadで表示されない場合</p>
                        <p className="arrow mod_txt_default mb_16">お手数ですが、iPadのバージョンをアップデートいただきますようお願いいたします。</p>

                        <div className="inner_box mb_16">
                            <p className="title ly_flex left">
                                <img src="/asset/img/pages/top/report_problem_24px_rounded.svg" alt=""></img>
                                <span className="pl_4">
                                    アップデートできない、又はアップデートしても表示されない場合
                            </span>
                            </p>
                            <p className="fz_12">お手数ですが、下記手順1〜3の通りに設定変更し、Safariでアクセスいただきますようお願いいたします。</p>
                        </div>
                        <p>
                            <img src="/asset/img/pages/top/img_modal_ipad.png" alt="" className="ly_center"></img>
                        </p>
                        <span>
                            <p className="fz_16 mb_8 ta_r">※一度設定いただきますと保持されます。</p>
                        </span>
                        <p className="mb_8 ta_c">
                            <Link to='#' onClick={handleNext} className="btn_primary middle">SMILE TVに進む</Link>
                        </p>
                    </div>
                </div>
                <div className="closeModal icon" onClick={handleCancel}>
                    <img src="/asset/img/common/icons/close_24px_rounded.svg" alt=""></img>
                </div>
            </div>
        </section>
    );
};

export default IFrameUnavailableModal;