import React, { useState, useContext } from 'react';

import { useRecctorManuals } from "../utils/useRecctorManuals"
import { OpenCloseContext } from '../context/OpenCloseContext';

const SpHeader = () => {

    const { existManual } = useRecctorManuals();
    const { meetingOpened, contestOpened } = useContext(OpenCloseContext).top;

    const [burgerBtnOpend, setBurgerBtnOpend] = useState(false);

    const handleBurgerBtnClick = () => {
        setBurgerBtnOpend(!burgerBtnOpend);
        if (burgerBtnOpend) {
            document.body.classList.add('noscroll');
        } else {
            document.body.classList.remove('noscroll');
        }
    };

    return (
        <span className="hide_pc_tab">
            <div className="mod_header_sp">
                <a href="/hr">
                    <img src="/asset/img/common/logo/logo_smile_4_parallel.svg" alt="" className="ly_center logo"></img>
                </a>
                <div className={`burger-btn ${burgerBtnOpend ? 'close' : ''}`} onClick={handleBurgerBtnClick}>
                    <span className="bar bar_top"></span>
                    <span className="bar bar_mid"></span>
                    <span className="bar bar_bottom"></span>
                </div>
            </div>

            <div className={`sp_nav_inner ${burgerBtnOpend ? 'slide-in' : ''}`}>
                <nav className="nav_inner">
                    <ul className="reset_list">
                        <li>
                            <a href="/hr/recruitment" className="mod_txt_h2">採用サポート</a>
                        </li>
                        <li>
                            <a href="/hr/cases" className="mod_txt_h2">情報共有</a>
                        </li>
                        <li>
                            <a href="https://recctor.jp/#/login/a5N4FuyuLsHw6UKLvYwv" className="mod_txt_h2" target="_recctorLogin">リクター</a>
                        </li>
                        {
                            existManual && (
                                <li>
                                    <a href="/hr/recctor-manuals" className="mod_txt_h2">リクターマニュアル</a>
                                </li>
                            )
                        }
                        {meetingOpened ?
                            <li>
                                <a href="/hr/meeting" className="mod_txt_h2">MEETING</a>
                            </li> : <></>
                        }
                        <li>
                            <a href="/hr/tv" className="mod_txt_h2">SMILE TV</a>
                        </li>
                        <li>
                            <a href="https://www2.ms-r.com/MSR/Partner/User/index.asp" className="mod_txt_h2" target="_ms">ミステリーショッパー</a>
                        </li>
                        {contestOpened ?
                            <li>
                                <a href="/hr/challenge" className="mod_txt_h2">SMILE Challenge</a>
                            </li> : <></>
                        }
                        <li>
                            <a href="https://signage.31ice.com" target="_ds" className="mod_txt_h2">Digital Signage</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </span>
    );
};

export default SpHeader;
