import {useEffect, useState} from "react"
import ApiHandler from "../utils/ApiHandler"

export const NotificationInfo = () => {
  const [infos, setInfos] = useState([])
  useEffect(() => {
    new ApiHandler('getInfoNotifications', data => {
      if (data && data.infos && Array.isArray(data.infos)) {
        setInfos(data.infos.filter(e => !!e.text))
      }
    }).execute({});
  }, []);

  return infos.length > 0 && (
    <>
      <div className="ly_flex center content pt_24 mb_24">
        <div className="width_default_pc560">
          <img src="/asset/img/pages/top/smile_info.png" width="119" alt="SMILEinfo" />
        </div>
      </div>

      <div className="sp_default">
        <div className="mod_list mb_40">
          <ul className="reset_list ly_center">
          {
            infos.map(({text, emphasize}, i) => (
              <li className={`ly_flex ${emphasize ? 'list_alert' : ''}`}>
                <span className="ly_flex ly_align_center ly_row h_auto w100p left border_none flavor_none">
                  <span className="mw_30">
                    <img src="/asset/img/pages/top/bell_red.svg" className="bell_red" width="20" alt="ベル" />
                    <img src="/asset/img/pages/top/bell.svg" className="bell" width="20" alt="ベル" />
                  </span>
                  <span className="ly_flex_vertical center pl_8 fz_14 fw_b ws_pre">{text}</span>
                </span>
              </li>
            ))
          }
          </ul>
        </div>
      </div>
    </>
  )
}