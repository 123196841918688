import React, { useState, useEffect, useContext } from 'react';

import SpHeader from '../../components/SpHeader';
import PcHeader from '../../components/PcHeader';
import PcFooter from '../../components/PcFooter';

import Loading from '../../components/Loading';
import ApiHandler from '../../utils/ApiHandler';
import { createGoogleFormURL } from '../../utils';
import { OpenCloseContext } from '../../context/OpenCloseContext';

export default function AmbassadorChallenge() {

    const {
        entryOpened,
        testOpened,
        courseOpened,
        videoEntryOpened,
    } = useContext(OpenCloseContext).ambassadorChallenge;

    const [forms, setForms] = useState({});
    const [courseURL, setCourseURL] = useState('');

    const [formLoaded, isFormLoaded] = useState(false);
    const [urlLoaded, isUrlLoaded] = useState(false);

    useEffect(() => {
        document.title = '31staff Dream Contest TOP';
        const handleRequest = () => {
            new ApiHandler('getForms', data => {
                setForms(data);
                isFormLoaded(true);
            }).execute({ category: 'contest' });

            new ApiHandler('getConfigURL', data => {
                setCourseURL(data?.['アンバサダーチャレンジトップ']?.['WEB特別講座']);
                isUrlLoaded(true);
            }).execute({});
        };
        handleRequest();
    }, []);

    const createLinkList = () => {
        return (
            <ul className="reset_list ly_center">
                <li>
                    <a href={createGoogleFormURL(forms.ambassadorChallengeEntry.formId)} target={`_${forms.ambassadorChallengeEntry.windowName}`} className={entryOpened ? '' : 'disable'}>
                        <span className="ly_flex wrap">
                            <p><img src="/asset/img/pages/smile_challenge/icon_step1.svg" width="48" alt="Step 1"></img></p>
                            <h3 className="ly_flex_vertical center pl_8"><span>{forms.ambassadorChallengeEntry.name}</span></h3>
                        </span>
                    </a>
                </li>
                <li>
                    <a href="https://td.tnweb.biz/tesign/entry/login/login.asp?u=gpns3486" target="_tesign" className={testOpened ? '' : 'disable'}>
                        <span className="ly_flex wrap">
                            <p><img src="/asset/img/pages/smile_challenge/icon_step2.svg" width="48" alt="Step 2"></img></p>
                            <h3 className="ly_flex_vertical center pl_8"><span>Step.1 WEB試験</span></h3>
                        </span>
                    </a>
                </li>
                <li>
                    <a href={courseURL} target="_lecture" className={courseOpened ? '' : 'disable'}>
                        <span className="ly_flex wrap">
                            <p><img src="/asset/img/pages/smile_challenge/icon_step3.svg" width="48" alt="Step 3"></img></p>
                            <h3 className="ly_flex_vertical center pl_8"><span>Step.2 WEB特別講座</span></h3>
                        </span>
                    </a>
                </li>
                <li>
                    <a href="/hr/challenge/dream-contest/entry/video" className={videoEntryOpened ? '' : 'disable'}>
                        <span className="ly_flex wrap">
                            <p><img src="/asset/img/pages/smile_challenge/icon_step4.svg" width="48" alt="Step 4"></img></p>
                            <h3 className="ly_flex_vertical center pl_8"><span>Step.3 接客動画Challenge</span></h3>
                        </span>
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <>
            <SpHeader></SpHeader>
            <PcHeader></PcHeader>

            <div className="content ly_is_nav">
                <div className="page_kv">
                    <img src="/asset/img/pages/smile_challenge/31_staff_dream_contest.png" alt="" className="ly_center"></img>
                </div>
                <div className="width_default_pc1030">
                    <h2 className="mod_page_title">31staff Dream Contest TOP</h2>
                </div>
                <div className="sp_default">
                    <div className="mod_list h64 mb_40">
                        {forms.ambassadorChallengeEntry ? createLinkList() : <></>}
                    </div>
                </div>
            </div>

            <Loading show={formLoaded === false || urlLoaded === false} />
            <PcFooter></PcFooter>
        </>
    );
}