import React, { useState } from 'react';
import ApiHandler from '../utils/ApiHandler';

import './FadeOut.css';
import Loading from './Loading';

const MeetingPassModal = (props) => {

    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, isLoading] = useState(false);

    const handleCancel = () => {
        props.handleCancel();
        setPassword('');
        setErrorMessage('');
    };
    const handleNext = async () => {
        isLoading(true);

        const apiHandler = new ApiHandler('validateMeetingPassword', validateResult => {
            if (validateResult.isValid) {
                new ApiHandler('getConfigURL', config => {
                    const url = config?.['店長会議トップ']?.['会議視聴'];
                    if (url) {
                        window.location.href = url;
                    } else {
                        alert('問題が発生しました。しばらく立ってから再度お試しください')
                        isLoading(false);
                    }
                }).execute({});
            } else {
                setErrorMessage('パスコードが違います');
                isLoading(false);
            }
        });
        apiHandler.execute({ password });
    };

    return (
        <section id="modalArea" className={`modalArea ${props.classes}`}>
            <div id="modalBg" className="modalBg" onClick={handleCancel}></div>
            <div className="modalWrapper">
                <div className="modalContents">
                    <span className="ta_c">
                        <p className="mod_txt_h2 mb_16 mt_64">パスコード</p>
                        <p className="mod_txt_message fw_n mb_24">「team_31@31ice.co.jp」より送付された<br></br>パスコードを下記に入力し視聴ページにお進みください。</p>
                    </span>

                    {/* <!-- Error --> */}
                    {errorMessage ? (
                        <div id="error_login">
                            <p className="mod_txt_caption tcr_attention fw_b mb_8">{errorMessage}</p>
                        </div>) : <></>}
                    {/* <!-- /Error --> */}

                    <div className="mb_40">
                        <h3 className="mod_txt_caption mb_8">パスコード</h3>
                        <input type="password" name="password" className={`form_text ${errorMessage ? 'alert' : ''}`} placeholder=""
                            value={password} onChange={e => setPassword(e.target.value)} disabled={loading}></input>
                        {/* <!-- .alert のつけ外しで赤枠が適用されます --> */}
                    </div>

                    <div className="mb_40 ta_c ly_flex center">
                        <span className="mr_8">
                            <p className="btn_secondary small ly_center mb_16 closeModal" onClick={handleCancel}>キャンセル</p>
                        </span>
                        <span className="ml_8">
                            <input type="submit" value="次へ" className="btn_submit small ly_center mb_16"
                                onClick={handleNext} disabled={loading}></input>
                        </span>
                    </div>
                </div>

                <div className="closeModal icon" onClick={handleCancel}>
                    <img src="/asset/img/common/icons/close_24px_rounded.svg" alt=""></img>
                </div>
            </div>
            <Loading show={loading} transparent={loading} />
        </section>
    );
};

export default MeetingPassModal;