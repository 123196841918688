import useSWR from "swr"
import ApiHandler from "./ApiHandler"

export const useRecctorManuals = () => {
  const {data, ...rest} = useSWR("/recctor-manuals", () => {
    return new Promise((resolve, reject) => {
      new ApiHandler("getRecctorManualFileNames", data => {
        resolve(data?.recctorManuals ?? [])
      }).execute({}, reject)
    })
  })

  return {
    data,
    ...rest,
    existManual: Array.isArray(data) && data.length > 0
  }
}