import React, {useEffect, useState} from "react"

export const Spinner = ({text}) => {

  const [angle, setAngle] = useState(0)

  useEffect(() => {
    let angleCounter = 0
    let loop
    const loopLoading = () => {
      loop = setInterval(() => {
        setAngle(++angleCounter)
        if (angleCounter > 360) {
          angleCounter = 0
        }
      }, 10)
    }
    loopLoading()
    return function cleanup() {
      clearInterval(loop)
    }
  }, [])

  return (
    <p className={"spinner"} >
      <img src="/asset/img/common/icons/icon_loading.png" alt="" style={{ transform: `rotate(${angle}deg)` }} />
      <span className="text">{text}</span>
    </p>
  )
}