import React, { useState, useEffect } from 'react';

import ApiHandler from '../../utils/ApiHandler';
import firebase from 'firebase/app';
import 'firebase/storage';
import Loading from '../../components/Loading';

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const REQUIRED_MESSAGE = 'この項目は必須です';

export default function AmbassadorChallengeVideoEntryForm() {

    const [divisionName, setDivisionName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [enthusiasm, setEnthusiasm] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [validationErrors, setValidationErrors] = useState({});
    const [loading, isLoading] = useState(false);

    const log = (indication) => {
      console.log(indication);
    }
  
    useEffect(() => {
        document.title = 'SMILE 動画エントリーフォーム';
    }, []);

    const validateRequired = (value, propertyName) => {
        const errors = Object.assign({}, validationErrors);
        if (!value) {
            errors[propertyName] = REQUIRED_MESSAGE;
        } else {
            delete errors[propertyName];
        }
        setValidationErrors(errors);
    }

    const validateEmail = (value) => {
        const errors = Object.assign({}, validationErrors);
        if (!value) {
            errors.email = REQUIRED_MESSAGE;
        } else if (!EMAIL_REGEX.test(value)) {
            errors.email = '有効なメールアドレスを入力してください';
        } else {
            delete errors.email;
        }
        setValidationErrors(errors);
    }

    const validate = () => {
        const errors = {};
        if (!divisionName) {
            errors.divisionName = REQUIRED_MESSAGE;
        }
        if (!storeName) {
            errors.storeName = REQUIRED_MESSAGE;
        }
        if (!name) {
            errors.name = REQUIRED_MESSAGE;
        }
        if (!email) {
            errors.email = REQUIRED_MESSAGE;
        } else if (!EMAIL_REGEX.test(email)) {
            errors.email = '有効なメールアドレスを入力してください';
        }
        if (!enthusiasm) {
            errors.enthusiasm = REQUIRED_MESSAGE;
        }
        if (!selectedFile || !selectedFile.name) {
            errors.fileName = REQUIRED_MESSAGE;
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = () => {
        if (validate() === false) {
            return;
        }

        isLoading(true);
        const successCallback = data => {
            uploadVideo(data.id);
        };
        const errorCallback = () => {
            alert('動画エントリーに失敗しました。しばらくしてから再度お試しください');
        };
        new ApiHandler('videoEntry', successCallback).execute({divisionName, storeName, name, email, enthusiasm, originalFileName: selectedFile.name}, errorCallback, errorCallback);

    }

    const uploadVideo = (objectName) => {
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef.child(`video-entries/${objectName}`).put(selectedFile);
    
        uploadTask.on('state_changed', function(snapshot){
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              console.error('unknown state:' + snapshot.state);                
          }
        }, function(error) {
            console.error(error);
            isLoading(false);
            alert('動画エントリーに失敗しました。しばらくしてから再度お試しください');
        }, function() {
            isLoading(false);
            window.location.href = "/hr/challenge/dream-contest/entry/video/complete";
        });
    }

    const entryScreen = () => {
        return (
            <div id="movie_entry_form" className="content bg_base_gray">
                <div className="bg_base_white padding_inner">
                    <h2 className="mod_page_title hide_sp">動画エントリーフォーム</h2>
                    <div className="mx_700">
                        <form>
                            <div className="row">
                                <h3 className="title"><span>ディビジョン</span></h3>
                                <div className={"tooltip_wrap w40p " + (validationErrors.divisionName ? 'active' : '')}>
                                    <label className="form_select">
                                        <select name="divisionName" value={divisionName}
                                            onChange={event => {
                                                const value = event.target.value;
                                                setDivisionName(value);
                                                validateRequired(value, 'divisionName');
                                            }}
                                        >
                                            <option value=''>選択してください</option>
                                            <option value='東京・神奈川・山梨・静岡ディビジョン'>東京・神奈川・山梨・静岡ディビジョン</option>
                                            <option value='千葉・埼玉・信越・北関東ディビジョン'>千葉・埼玉・信越・北関東ディビジョン</option>
                                            <option value='東北・北海道ディビジョン'>東北・北海道ディビジョン</option>
                                            <option value='中部・北陸ディビジョン'>中部・北陸ディビジョン</option>
                                            <option value='関西・中国・四国ディビジョン'>関西・中国・四国ディビジョン</option>
                                            <option value='九州・山口ディビジョン'>九州・山口ディビジョン</option>
                                        </select>
                                    </label>
                                    <div className="tooltip_description">{validationErrors.divisionName}</div>
                                </div>
                            </div>
                            <div className="row">
                                <h3 className="title"><span>店舗名</span></h3>
                                <div className={"tooltip_wrap w100p " + (validationErrors.storeName ? 'active' : '')}>
                                    <input
                                        type="text"
                                        value={storeName}
                                        onChange={(e) => setStoreName(e.target.value)}
                                        onBlur={(e) => validateRequired(e.target.value, 'storeName')}
                                        className="form_text"
                                    ></input>
                                    <div className="tooltip_description">{validationErrors.storeName}</div>
                                </div>
                            </div>
                            <div className="row">
                                <h3 className="title"><span>氏名</span></h3>
                                <div className={"tooltip_wrap w100p " + (validationErrors.name ? 'active' : '')}>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        onBlur={(e) => validateRequired(e.target.value, 'name')}
                                        className="form_text"
                                    >
                                    </input>
                                    <p className="mod_txt_caption tcr_red">※姓と名の間を空けずに入力お願いいたします。例：山田花子</p>
                                    <div className="tooltip_description">{validationErrors.name}</div>
                                </div>
                            </div>
                            <div className="row">
                                <h3 className="title"><span>メールアドレス</span></h3>
                                <div className={"tooltip_wrap w100p " + (validationErrors.email ? 'active' : '')}>
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={(e) => validateEmail(e.target.value)}
                                        className="form_text"
                                    >
                                    </input>
                                    <p className="mod_txt_caption">※参加登録と同じメールアドレスをご入力ください</p>
                                    <div className="tooltip_description">{validationErrors.email}</div>
                                </div>
                            </div>
                            <div className="row">
                                <h3 className="title"><span>接客のこだわり<br></br>意気込み</span></h3>
                                <div className={"tooltip_wrap w100p " + (validationErrors.enthusiasm ? 'active' : '')}>
                                    <textarea
                                        value={enthusiasm}
                                        onChange={(e) => setEnthusiasm(e.target.value)}
                                        onBlur={(e) => validateRequired(e.target.value, 'enthusiasm')}
                                        cols="30"
                                        rows="4"
                                        className="form_textarea"
                                    >
                                    </textarea>
                                    <div className="tooltip_description">{validationErrors.enthusiasm}</div>
                                </div>
                            </div>
                            <div className="row">
                                <h3 className="title"><span>動画</span></h3>
                                <div className={"tooltip_wrap " + (validationErrors.fileName ? 'active' : '')}>
                                    <div className="file">
                                        <input
                                            type="file"
                                            accept='video/*'
                                            onChange={(e) => {
                                                const file = e.target.files && e.target.files[0]
                                                if (!file) {
                                                    setSelectedFile(null);
                                                    validateRequired('', 'fileName');
                                                } else {
                                                    setSelectedFile(file);
                                                    validateRequired('dummy', 'fileName');
                                                }
                                            }}
                                            className="file_upload"
                                            id="image1">
                                        </input>
                                        <label htmlFor="image1" className={selectedFile ? 'changed' : ''}>
                                            <img src="/asset/img/common/icons/upload_24px_rounded.svg" alt="" className="ly_center"></img>
                                        </label>
                                        {selectedFile ? <span className="filename">{selectedFile.name}</span> : <></>}
                                    </div>
                                    <p className="mod_txt_caption">※エントリーは1人1回のみとなります。</p>
                                    <div className="tooltip_description">{validationErrors.fileName}</div>
                                </div>
                            </div>

                            <div className="mb_40 ta_c ly_flex center wrap">
                                <span>
                                    <a href="/hr/challenge/dream-contest" className="btn_secondary middle ly_center mb_16 closeModal">戻る</a>
                                </span>
                                <span className="hide_sp mr_8 ml_8">&nbsp;</span>
                                <span>
                                    <input type="button" value="送信" className="btn_submit middle ly_center" onClick={handleSubmit}></input>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <span className="hide_pc_tab">
                <div className="mod_header_sp">
                    <span className="left ly_flex_vertical center">
                        <span className="mod_txt_h3 tcr_primary">
                            動画エントリーフォーム
                        </span>
                    </span>
                    <div className="burger-btn" style={{ display: 'none' }}>
                        <span className="bar bar_top"></span>
                        <span className="bar bar_mid"></span>
                        <span className="bar bar_bottom"></span>
                    </div>
                </div>
            </span>
            <span className="hide_sp">
                <div className="mod_header_pc ly_flex left">
                    <div className="ly_flex">
                        <div>
                            <p>
                                <a href="/hr">
                                    <img src="/asset/img/common/logo/logo_smile_4_parallel.svg" alt="" className="ly_center"></img>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </span>
            {entryScreen()}
            <Loading show={loading} transparent />
            <footer id="footer_default" className="bg_base_gray">
                <span className="copy ta_c ly_center mod_txt_copy">Copyright &copy; B-R 31 ICE CREAM CO., LTD. All rights reserved.</span>
            </footer>
        </>
    );
}