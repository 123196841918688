import React, { useContext, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import SpHeader from '../../components/SpHeader';
import PcHeader from '../../components/PcHeader';
import PcFooter from '../../components/PcFooter';
import { OpenCloseContext } from '../../context/OpenCloseContext';
import { DisplayContext } from '../../context/DisplayContext';
import ApiHandler from '../../utils/ApiHandler';
import Loading from '../../components/Loading';

export default function Challenge() {

    const {
        ambassadorChallengeOpened,
        hometatsuFormOpened,
        hometatsuZoomOpened,
    } = useContext(OpenCloseContext).challenge;

    const {
        hometatsuFormDisplayed,
        hometatsuZoomDisplayed,
    } = useContext(DisplayContext).challenge;

    const [loading, isLoading] = useState(true);

    const [hometatsuFormURL, setHometatsuFormURL] = useState('');
    const [hometatsuZoomURL, setHometatsuZoomURL] = useState('');
    const [hometatsuFormTitleHTML, setHometatsuFormTitleHTML] = useState('');

    useEffect(() => {
        document.title = 'SMILE Challenge TOP';

        new ApiHandler('getConfigURL', data => {
            const config = data?.['チャレンジトップ']?.['ほめ達'];
            setHometatsuFormURL(config?.['フォーム']);
            setHometatsuZoomURL(config?.['ZOOM']);
            isLoading(false);
        }).execute({});

        firebase.database().ref('configs/titleHTML').on('value', (snapshot) => {
            const data = snapshot.val()
            setHometatsuFormTitleHTML(data?.['ほめ達']?.['フォーム']);
        });
    }, []);

    return (
        <>
            <SpHeader></SpHeader>
            <PcHeader></PcHeader>

            <div className="content ly_is_nav">
                <div className="width_default_pc1030">
                    <h2 className="mod_page_title">SMILE Challenge TOP</h2>
                </div>
                <div className="sp_default">
                    <div className="mod_list mb_40">
                        <ul className="reset_list ly_center">
                            <li>
                                <a href="/hr/challenge/dream-contest" className={ambassadorChallengeOpened ? '' : 'disable'}>
                                    <h3>31staff Dream Contest</h3>
                                </a>
                            </li>
                            {hometatsuFormDisplayed ?
                                <li>
                                    <a href={hometatsuFormURL} target="_hometatsu" className={hometatsuFormOpened ? '' : 'disable'}>
                                        <span className="ly_flex wrap">
                                            <p><img src="/asset/img/pages/smile_challenge/logo_hometatsu.png" alt="ほめたつ"></img></p>
                                            <h3 className="ly_flex_vertical center pl_8" dangerouslySetInnerHTML={{ __html: hometatsuFormTitleHTML }}></h3>
                                        </span>
                                    </a>
                                </li>
                                : <></>}
                            {hometatsuZoomDisplayed ?
                                <li>
                                    <a href={hometatsuZoomURL} target="_hometatsu" className={hometatsuZoomOpened ? '' : 'disable'}>
                                        <span className="ly_flex wrap">
                                            <p><img src="/asset/img/pages/smile_challenge/logo_hometatsu.png" alt="ほめたつ"></img></p>
                                            <h3 className="ly_flex_vertical center pl_8">参加ページ</h3>
                                        </span>
                                    </a>
                                </li>
                                : <></>}
                        </ul>
                    </div>
                    <div className="mx_950 ly_center">
                        <p className="tcr_red fw_b mb_8">31staff Dream Contest（WEB特別講座）・ほめ達マスター研修を受講する皆さまへ</p>
                        <p className="mb_32">
                            事前にZoomのインストールをお願いいたします。<br />
                            インストール済みの方は、バージョン5.2以上であるかご確認ください。<br />
                            5.2以上でない方は、事前にアップデートいただきますようお願いいたします。
                        </p>
                        <p className="fw_b mb_8">【Zoomバージョン確認方法】</p>
                        <p className="mb_16"><img src="/asset/img/pages/smile_challenge/img_info_01.png" alt="Zoomバージョン確認方法" className="ly_center" /></p>
                    </div>
                </div>
            </div>
            <Loading show={loading} />
            <PcFooter></PcFooter>
        </>
    );
}