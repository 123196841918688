import React, { useEffect, useState } from "react"

import firebase from 'firebase/app';
import 'firebase/analytics';

import SpHeader from "../../components/SpHeader"
import PcHeader from "../../components/PcHeader"
import Loading from "../../components/Loading"
import PcFooter from "../../components/PcFooter"
import ApiHandler from "../../utils/ApiHandler"
import { firebaseFunctionsBaseURL } from "../../utils/FirebaseInitializer"
import { needToSendAnalytics } from "../../utils";

const BASE_URL = firebaseFunctionsBaseURL();

class UploadFile {
    constructor(key, entity) {
        this.key = key;
        this.title = entity.title;
        this.updateAt = entity.updateAt;
        this.url = `${BASE_URL}caseFile?key=${this.key}`;
    }
}

export default function Cases() {

    const [loading, isLoading] = useState(true);
    const [cases, setCases] = useState([]);

    useEffect(() => {
        document.title = 'SMILE 媒体掲載好事例・時給情報'
        const handleRequest = () => {
            const apiHandler = new ApiHandler('cases', data => {
                if (data) {
                    setCases(Object.keys(data).map(key => new UploadFile(key, data[key])).sort((e1, e2) => e1.updateAt.localeCompare(e2.updateAt) * -1));
                }
                isLoading(false);
            });
            apiHandler.execute({});
        };
        handleRequest();
    }, [])

    const clickHandler = (item) => {
        if (needToSendAnalytics()) {
            firebase.analytics().logEvent('select_content', {
                content_type: 'cases',
                item_id: item.title,
            });
        }
    };

    return (
        <>
            <SpHeader />
            <PcHeader />

            <div className="content ly_is_nav">
                <div className="width_default_pc950">
                    <div className="ly_flex sp_vertical wrap side">
                        <h2 className="mod_page_title pl_0">媒体掲載好事例・時給情報</h2>
                        <div className="ml_16 mr_16">
                            <div className="mt_40 hide_sp"></div>
                            <a href="https://forms.gle/eWrZgNGA1Y41Dfaf9" target="_casesForm" className="btn_secondary dark large ly_center mb_16">事例共有フォーム</a>
                        </div>
                    </div>
                </div>
                <div className="sp_default">
                    <div id="jirei_top" className="mod_list mb_40">
                        <ul className="reset_list ly_center">
                            {cases.map((item, index) => {
                                return (
                                    <li key={`row-${index}`} >
                                        <a target={"_caseFile"} href={item.url} onClick={() => clickHandler(item)}>
                                            <span className="ly_flex">
                                                <p><img src="/asset/img/common/icons/icon_pdf.svg" alt="PDFアイコン" /></p>
                                                <h3 className="ly_flex_vertical center pl_8"><span>{item.title}</span></h3>
                                            </span>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <Loading show={loading} />
            <PcFooter />
        </>
    )
}
