import React from 'react'
import classNames from "classnames"

import './FadeOut.css'
import {Spinner} from "./Spinner"
import {Overlay} from "./Overlay"

const Loading = ({show, transparent}) => {
  return (
    <div className={
      classNames(
        "loading",
        "foeShow",
        {
          hide: !show
        }
      )}
    >
      <Overlay transparent={transparent} />
      <div className="wrap_spinner">
        <Spinner text={"LOADING"} />
      </div>
    </div>
  )
}

export default Loading